.IndicatorWrapper {
    width: 200px;
    margin: 0 auto;    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.CarouselIndicator {
    width: calc(33% - 2px);
    margin: 0 2px;
    height: 1px;
    background-color: white;
}

.CarouselIndicator.Active {
    background-color: #CCA778;
}