.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 6%;
    padding-top: 0;
}

.SectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.StepWrapper {
    width: 50%;
}

.FormWrapper {
    width: 50%;
    max-width: 450px;
    margin-left: auto;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 48px;

    box-sizing: border-box;
    padding: 4% 3%;
    
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 4px #ccc;
}

.MainHeading {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
}

.FormHeading {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0 0 24px 0;
}

.InputBox {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    margin: 16px 0;
    border: 0;
    outline: none;
    background-color: white;
    box-sizing: border-box;
}

.ButtonWrapper {
    margin-top: 24px;
}

@media (max-width: 600px) {
    .MainContainer {
        padding: 0;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .SectionWrapper {
        flex-direction: column;
    }

    .MainHeading {
        font-size: 28px;
        margin-top: 60px;
    }

    .StepWrapper {
        width: 100%;
    }

    .FormWrapper {
        width: 100%;
        margin-top: 32px;
        padding: 24px;
        border-radius: 24px;
        background-size: cover;
    }

    .InputBox {
        width: 100%;
        margin-bottom: 16px;
    }
}