.MainContainer {
    width: 100%;
    height: 527px;

    position: relative;
}

.LandingSectionBg {
    width: 100%;
    height: 527px;
    /* background: linear-gradient(135deg , #DFF5F7, #EAD2F3); */

    margin-top: 20px;
    border-radius: 60px;

    position: absolute;
    top: -20px;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.LandingSectionBg > img {
    width: 100%;
}

.ContentWrapper {
    padding: 0 8%;
    height: 527px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MainHeading {
    font-size: 68px;
    font-weight: 500 !important;
    line-height: 1;
    letter-spacing: -1px;
    margin: 0 0 48px 0;
}

.MainHeading > span {
    font-size: 72px;
    font-weight: 900;
}

.USP > p{
    font-size: 15px;
    font-weight: 500;
    margin: 8px 0;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 1px;
}

.USP i {
    font-size: 8px;
    margin-right: 8px;
}

.ButtonWrapper {
    margin-top: 32px;
}

.RightSection {
    width: 50%;
    position: relative;
}

.CarIllustration {
    width: 450px;
    position: absolute;
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
}

@media (max-width: 600px) {
    .LandingSectionBg img {
        display: none;
    }

    .LandingSectionBg {
        min-height: 100vh;
        background-image: url('../../../../assets/landing-bg.png');
        background-size: cover;

        padding: 24px;
        border-radius: 24px;
        box-sizing: border-box;
    }

    .MainContainer {
        min-height: 100vh;
    }

    .ContentWrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 24px;
        overflow: hidden;
    }

    .MainHeading {
        font-size: 36px;
        font-weight: 500 !important;
        line-height: 1;
        letter-spacing: -1px;
        margin: 0 0 48px 0;
    }
    
    .MainHeading > span {
        font-size: 40px;
        font-weight: 900;
    }

    .RightSection {
        width: 100%;
    }

    .CarIllustration {
        position: static;
        width: 100%;
        transform: none;
        margin-top: 48px;
    }
}