.MainContainer {
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 16px 8%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TopbarShadow {
    box-shadow: 0 0px 4px #ccc;
}

.Logo {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    /* font-style: italic; */
    text-transform: uppercase;
}

.Logo span {
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-right: 8px;
    color: var(--primary-color);
}

.MenuItem {
    font-size: 14px;
    font-weight: 500;
    color: var(--txt-primary-color);
    margin: 0 14px;
}

.BtnGetQuote {
    margin-left: 32px;
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 12px 32px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
}

.BtnGetQuote:hover {
    background-color: var(--primary-color);
    color: white;
}

@media (max-width: 600px) {
    .MenuItem {
        display: none;
    }

    .BtnGetQuote {
        display: none;
    }
}