.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.MainHeading {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
}

.StepsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.StepItem {
    width: 100%;
    margin-right: 60px;
    margin-top: 36px;
}

.StepItem:last-of-type {
    margin-right: 0;
}

.StepCount {
    font-weight: 900;
    font-size: 24px;
    margin: 0;
}

.StepLabel {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
}

.StepDesc {
    font-size: 14px;
    margin: 4px 0 0;
    color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 600px) {
    .MainContainer {
        padding: 0;
    }
    
    .MainHeading {
        font-size: 28px;
        margin-top: 60px;
    }

    .StepsWrapper {
        align-items: flex-start;
        text-align: left;
        flex-wrap: wrap;
    }

    .StepCount {
        font-size: 48px;
    }

    .StepItem {
        width: 100%;
        margin-bottom: 0;
    }
}