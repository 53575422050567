.ServiceItem {
    box-shadow: 0 0 4px #ccc;
    border-radius: 8px;
    padding: 16px;
    width: calc(20% - 16px);
    text-align: center;
    color: #2F2467;
    margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ServiceItem i {
    font-size: 40px;
}

.ServiceName {
    width: 100%;
    margin-bottom: 0;
    font-size: 14px;
}

@media (max-width: 600px) {
    .ServiceItem {
        width: calc(50% - 60px);
    }
}