.MainContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 6% 0;
}

.MainHeading {
    width: 100%;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
}

.BoldText {
    font-weight: 900;
}

.ServiceCategory {
    display: inline-block;
    margin-left: 12px;
    padding: 4px 24px;
    border-radius: 24px;
    background-color: #D4D3E0
}

.ServiceGrid {
    display: flex;
    flex-wrap: nowrap;
}

.InsuranceTypeWrapper {
    padding: 48px;
    box-shadow: 0 0 8px 1px #eee;
    border-radius: 48px;
}

.InsuranceTypeItem {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.InsuranceTypeItem:last-of-type {
    margin-bottom: 0;
}

.IconWrapper {
    width: 32px;
    height: 32px;
    padding: 16px;
    border-radius: 16px;
    margin-right: 24px;
}

.InsuranceTypeIcon {
    width: 32px;
    height: 32px;
}

.InsuranceTypeLabel {
    font-size: 20px;
    margin: 0;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.8);
}

.InsuranceTypeDesc {
    font-size: 14px;
    margin: 4px 0 0;
    color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 600px) {
    .MainContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .MainHeading {
        font-size: 28px;
        margin-top: 60px;
        text-align: center;
    }

    .ServiceCategory {
        box-sizing: border-box;
        text-align: center;
        margin-right: 12px;
    }

    .InsuranceTypeWrapper {
        border-radius: 24px;
        padding: 24px;
    }

    .ServiceGrid {
        display: flex;
        flex-wrap: wrap;
    }
}