.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 6%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.MainHeading {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
}

.TestimonialsWrapper {
    /* width: calc(100% - 12%); */
    width: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 48px;

    box-sizing: border-box;
    padding: 6%;
}

.LeftSection {
    width: 50%;
    text-align: left !important;
}

.TestimonialItem {
    width: 100%;
    height: 265px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TestimonialText {
    font-size: 36px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
}

.TestimonialUser {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    margin: 8px 0 0;
}

.TestimonialUserImage {
    width: 300px !important;
    margin-right: 50px;
}

@media (max-width: 600px) {
    .MainContainer {
        padding: 0;
        margin-top: 40px;
    }

    .MainHeading {
        font-size: 28px;
        margin-top: 60px;
    }
    
    .TestimonialsWrapper {
        border-radius: 24px;
        padding: 24px;
    }

    .TestimonialItem {
        height: 100%;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .LeftSection {
        width: 100%;
    }

    .TestimonialText {
        font-size: 24px;
    }


    .RightSection {
        width: 100%;
    }
}