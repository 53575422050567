@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto:wght@500;900&display=swap');

html {
  scroll-behavior: smooth;
}

.MainContainer {
  width: calc(100% - 16%);
  min-height: calc(100vh - 80px);
  padding-top: 85px;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

form, input, select, textarea {
  font-family: 'Poppins', sans-serif !important;
}

a {
  color: black;
  text-decoration: none;
  font-family: 'Poppins', sans-serif !important;
}

button {
  font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', serif !important;
}

:root {
  --primary-color: #2F2467;
  --heading-primary-color: rgba(0, 0, 0);
  --txt-primary-color: rgba(0, 0, 0, 0.8);
  --txt-secondary-color: rgba(255, 255, 255);
}

@media (max-width: 600px) {
  .MainContainer {
    width: calc(100% - 32px);
  }
}