.Footer {
    width: 100%;
    padding: 24px 8%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 0.9);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LoveText {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
}

.LoveText i {
    margin-left: 6px;
    margin-right: 6px;
    color: rgb(179, 32, 32);
}

.ContactInfo {
    margin: 0;
    font-size: 14px;
}

.ContactInfo > i {
    font-size: 14px;
    margin: 0 4px 0 0;
    display: inline-block;
}


@media (max-width: 600px) {
    .Footer {
        padding: 16px;
    }

    .ContactInfo {
        margin-top: 0px;
    }
}